import { memo } from "react";
import PropTypes from "prop-types";
// cmp
import ColorButton from "./color-button";
// services
import Constants from "../services/constants";

type Props = {
	min: number;
	max: number;
	large: boolean;
	onColorClick: (colorTemperatureMired: number) => void;
};

const ColorTemplatesTemp = ({min, max, large = false, onColorClick}: Props) => {
	const step = (max - min) / (Constants.ColorTemperatureTemplates.length - 1);
	return (
		<>
			{Constants.ColorTemperatureTemplates.map((colorTemperatureCode, index) => (
				<ColorButton
					key={colorTemperatureCode}
					color={colorTemperatureCode}
					large={large}
					onClick={() => (onColorClick(Math.round(max - step * index)))}
				/>
			))}
		</>
	);
};

ColorTemplatesTemp.defaultProps = {
	large: false,
};

ColorTemplatesTemp.propTypes = {
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	large: PropTypes.bool,
	onColorClick: PropTypes.func.isRequired,
};

export default memo(ColorTemplatesTemp);

import PropTypes from "prop-types";
import { Slider } from "@mui/material";
import { styled } from "@mui/material/styles";
// types
import type { CSSProperties } from "react";

type Props = {
	value: number;
	temperatureRange: [number, number];
	onChange: (temperature: number) => void;
	onChangeCommitted: (temperature: number) => void;
	style: CSSProperties;
};

const StyledSlider = styled(Slider)({
	"& .MuiSlider-rail": {
		height: "6px",
		padding: 0,
		background: "linear-gradient(to right, #ff9329 0%, #ffd6aa 22%, #fff1e0 42%, #ffffff 62%, #c9e2ff 82%, #409cff 100%) !important",
		borderRadius: "3px",
		opacity: 1,
	},
});

const ColorTemperatureSlider = ({ value, temperatureRange, onChange, onChangeCommitted, style }: Props) => (
	<StyledSlider
		min={-temperatureRange[1]}
		max={-temperatureRange[0]}
		track={false}
		style={style}
		value={-value}
		onChange={(event, value) => (onChange(-(value as number)))}
		onChangeCommitted={(event, value) => (onChangeCommitted(-(value as number)))}
	/>
);

ColorTemperatureSlider.defaultProps = {
	style: {},
};

ColorTemperatureSlider.propTypes = {
	value: PropTypes.number.isRequired,
	temperatureRange: PropTypes.arrayOf(PropTypes.number).isRequired,
	onChange: PropTypes.func.isRequired,
	onChangeCommitted: PropTypes.func.isRequired,
	style: PropTypes.object,
};

export default ColorTemperatureSlider;

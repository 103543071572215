import { forwardRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
// types
import type { CSSProperties, MouseEventHandler, ReactNode } from "react";

type Props = {
	children: ReactNode;
	className?: string;
	style?: CSSProperties;
	onClick?: MouseEventHandler<HTMLDivElement>;
};

const ListItemContainer = forwardRef<HTMLDivElement, Props>(({ children, ...props }, ref) => (
	<div ref={ref} {...props}>
		{children}
	</div>
));

if (process.env.NODE_ENV !== "production") {
	ListItemContainer.displayName = "ListItemContainer";
}

ListItemContainer.defaultProps = {
	className: "",
	style: {},
	onClick: () => {},
};

ListItemContainer.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	style: PropTypes.object,
	onClick: PropTypes.func,
};

const ListItemContainerStyled = styled(ListItemContainer, { name: "ListItemContainer" })({
	display: "inline-flex",
	justifyContent: "flex-end",
	alignItems: "center",
});

export default ListItemContainerStyled;

import { memo } from "react";
// cmp
import ColorButton from "./color-button";
// services
import Constants from "../services/constants";
import { hexToRgb } from "../services/color";
// types
import type { ColorRGB } from "../types/misc";

type Props = {
	large?: boolean;
	onColorClick: (value: ColorRGB) => void;
};

const ColorTemplatesRgb = ({large = false, onColorClick}: Props) => (
	<>
		{Constants.ColorTemplates.map((colorCode) => (
			<ColorButton
				key={colorCode}
				color={colorCode}
				large={large}
				onClick={() => (onColorClick(hexToRgb(colorCode)))}
			/>
		))}
	</>
);

export default memo(ColorTemplatesRgb);
